import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dataTypes', 'useCases', 'insight', 'nothing']

  initialize () {
    this.handleChange = this.handleChange.bind(this)
  }

  connect () {
    this.dataTypesTarget.addEventListener('change', this.handleChange)
    this.useCasesTarget.addEventListener('change', this.handleChange)

    this.updateForm()
    this.filter()
  }

  handleChange () {
    this.updateParams()
    this.filter()
  }

  updateForm () {
    const params = new URLSearchParams(window.location.search)

    this.dataTypesTarget.value = params.get('l') || ''
    this.useCasesTarget.value = params.get('t') || ''
  }

  updateParams () {
    const query = new URLSearchParams({
      l: this.dataTypesTarget.value,
      t: this.useCasesTarget.value
    }).toString()

    const path = window.location.origin + window.location.pathname + '?' + query
    window.history.pushState({ path }, '', path)
  }

  filter () {
    const query = [
      this.dataTypesTarget.value,
      this.useCasesTarget.value
    ].filter((v) => v).join(' ').toLowerCase().split(/\s+/)

    const isMatch = (query, value) => query.every((q) => value.find((v) => v.includes(q)) !== undefined)

    const wasMatch = this.insightTargets.reduce((acc, insight) => {
      const value = insight.dataset.searchValues.split(' ')

      if (isMatch(query, value)) {
        insight.classList.remove('hidden')
        return true
      } else {
        insight.classList.add('hidden')
        return acc
      }
    }, false)

    if (wasMatch) {
      this.nothingTarget.classList.add('hidden')
    } else {
      this.nothingTarget.classList.remove('hidden')
    }
  }
}
