import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['self', 'input', 'field']
  connect () {
    this.oldValue = ''
  }

  selfDescribe () {
    this.inputTarget.disabled = false
    this.selfTarget.checked = true
    this.inputTarget.focus()
    this.inputTarget.value = this.oldValue
  }

  selfSelection () {
    this.inputTarget.disabled = true
    this.oldValue = this.inputTarget.value
    this.inputTarget.value = ''
  }
}
