import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['block', 'button']
  connect () {
    this.buttonTargets[0].classList.add(...this.activeClass())
    this.buttonTargets.splice(1).forEach((b) => b.classList.add(...this.inactiveClass()))
  }

  activeClass () {
    return this.element.dataset.buttonClassActive.split(' ')
  }

  inactiveClass () {
    return this.element.dataset.buttonClassInactive.split(' ')
  }

  showTopic (e) {
    this.blockTargets.forEach((i) => i.classList.add('hidden'))
    this.buttonTargets.forEach((i) => i.classList.remove(...this.activeClass()))
    this.buttonTargets.forEach((i) => i.classList.add(...this.inactiveClass()))
    this.blockTargets[e.params.index].classList.remove('hidden')
    e.target.classList.add(...this.activeClass())
    e.target.classList.remove(...this.inactiveClass())
    e.preventDefault()
  }
}
